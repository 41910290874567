<template>
  <label class="relative flex flex-row">
    <input
      type="checkbox"
      :value="value"
      class="absolute opacity-0"
      @change="changed"
      v-bind="$attrs"
      v-validate="validation"
    >
    <img
      v-if="value"
      src="@/assets/images/radio_button_checked.svg"
      :alt="$t('general.label_checked')"
      class="h-6 w-6"
    >
    <img
      v-else
      src="@/assets/images/radio_button_unchecked.svg"
      :alt="$t('general.label_unchecked')"
      class="h-6 w-6"
    >
  </label>
</template>

<script>
  export default {
    name: 'VCheckbox',
    inheritAttrs: false,
    props: {
      value: { type: Boolean, default: false },
      validation: { type: String, default: '' },
    },
    inject: {
      $validator: '$validator',
    },
    methods: {
      changed() {
        this.$emit('input', !this.value);
      },
    },
  };
</script>
