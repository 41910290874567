var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col justify-between" }, [
    _c(
      "form",
      {
        staticClass: "flex flex-col mt-5",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass:
              "flex items-end border-b border-blue-500 text-center pb-3 relative"
          },
          [
            _c("h1", {
              staticClass: "w-full font-semibold",
              domProps: {
                textContent: _vm._s(_vm.$t("the_login.button_login"))
              }
            }),
            _c(
              "button",
              {
                staticClass:
                  "ml-auto mr-3 btn-round absolute right-0 top-0 flex items-center",
                class: {
                  "btn-loading": _vm.loading,
                  "btn-blue cursor-pointer": !_vm.loginDisabled,
                  "btn-blue-light cursor-not-allowed": _vm.loginDisabled
                },
                attrs: { type: "submit", disabled: _vm.loginDisabled },
                on: { click: _vm.onSubmit }
              },
              [
                !_vm.loading
                  ? _c("img", {
                      staticClass: "w-3",
                      attrs: {
                        src: require("@/assets/images/ic_chevron_right.svg"),
                        alt: _vm.$t("the_login.button_login"),
                        title: _vm.$t("the_login.button_login")
                      }
                    })
                  : _vm._e()
              ]
            )
          ]
        ),
        _c("div", [
          _c("ul", { staticClass: "list-disc list-inside" }, [
            _vm.error
              ? _c("li", {
                  staticClass: "form-error",
                  domProps: { textContent: _vm._s(_vm.error) }
                })
              : _vm._e(),
            _vm.errors.has("email")
              ? _c("li", {
                  staticClass: "form-error",
                  domProps: { textContent: _vm._s(_vm.errors.first("email")) }
                })
              : _vm._e(),
            _vm.errors.has("password")
              ? _c("li", {
                  staticClass: "form-error",
                  domProps: {
                    textContent: _vm._s(_vm.errors.first("password"))
                  }
                })
              : _vm._e(),
            _vm.errors.has("terms")
              ? _c("li", {
                  staticClass: "form-error",
                  domProps: { textContent: _vm._s(_vm.errors.first("terms")) }
                })
              : _vm._e()
          ]),
          _c("div", { staticClass: "form-fieldset" }, [
            _c("label", {
              staticClass: "form-input-label",
              attrs: { for: "email" },
              domProps: {
                textContent: _vm._s(_vm.$t("the_login.placeholder_email"))
              }
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'"
                }
              ],
              staticClass: "form-input",
              attrs: {
                type: "text",
                id: "email",
                name: "email",
                "data-vv-validate-on": "submit",
                autofocus: "",
                placeholder: _vm.$t("general.placeholder_required")
              },
              domProps: { value: _vm.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-fieldset" }, [
            _c("label", {
              staticClass: "form-input-label",
              attrs: { for: "password" },
              domProps: {
                textContent: _vm._s(_vm.$t("the_login.placeholder_password"))
              }
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "form-input",
              attrs: {
                type: "password",
                id: "password",
                name: "password",
                "data-vv-validate-on": "submit",
                placeholder: _vm.$t("general.placeholder_required")
              },
              domProps: { value: _vm.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                }
              }
            })
          ])
        ]),
        _c(
          "div",
          { staticClass: "ml-4" },
          [
            _c("router-link", {
              staticClass:
                "text-xs text-blue-500 hover:text-blue-600 focus:outline-none focus:text-blue-600",
              attrs: { to: { name: "password-reset" } },
              domProps: {
                textContent: _vm._s(_vm.$t("the_login.button_forgot_password"))
              }
            })
          ],
          1
        ),
        _c(
          "label",
          { staticClass: "flex mt-6 ml-4" },
          [
            _c("v-checkbox", {
              attrs: { name: "terms", validation: "required" },
              model: {
                value: _vm.acceptTerms,
                callback: function($$v) {
                  _vm.acceptTerms = $$v
                },
                expression: "acceptTerms"
              }
            }),
            _c("p", {
              staticClass: "w-3/4 ml-4 mt-1 text-sm leading-tight",
              domProps: {
                innerHTML: _vm._s(_vm.$t("the_login.label_accept_terms"))
              }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }