var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "relative flex flex-row" }, [
    _c(
      "input",
      _vm._b(
        {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: _vm.validation,
              expression: "validation"
            }
          ],
          staticClass: "absolute opacity-0",
          attrs: { type: "checkbox" },
          domProps: { value: _vm.value },
          on: { change: _vm.changed }
        },
        "input",
        _vm.$attrs,
        false
      )
    ),
    _vm.value
      ? _c("img", {
          staticClass: "h-6 w-6",
          attrs: {
            src: require("@/assets/images/radio_button_checked.svg"),
            alt: _vm.$t("general.label_checked")
          }
        })
      : _c("img", {
          staticClass: "h-6 w-6",
          attrs: {
            src: require("@/assets/images/radio_button_unchecked.svg"),
            alt: _vm.$t("general.label_unchecked")
          }
        })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }