<template>
  <div class="flex flex-col justify-between">
    <form
      class="flex flex-col mt-5"
      @submit.prevent="onSubmit">
      <div class="flex items-end border-b border-blue-500 text-center pb-3 relative">
        <h1
          v-text="$t('the_login.button_login')"
          class="w-full font-semibold"></h1>
        <button
          type="submit"
          class="ml-auto mr-3 btn-round absolute right-0 top-0 flex items-center"
          :class="{'btn-loading': loading,
                   'btn-blue cursor-pointer': !loginDisabled,
                   'btn-blue-light cursor-not-allowed': loginDisabled}"
          :disabled="loginDisabled"
          @click="onSubmit"
        >
          <img
            v-if="!loading"
            src="@/assets/images/ic_chevron_right.svg"
            class="w-3"
            :alt="$t('the_login.button_login')"
            :title="$t('the_login.button_login')"
          >
        </button>
      </div>
      <div>
        <ul class="list-disc list-inside">
          <li
            v-if="error"
            v-text="error"
            class="form-error"></li>
          <li
            v-if="errors.has('email')"
            v-text="errors.first('email')"
            class="form-error"></li>
          <li
            v-if="errors.has('password')"
            v-text="errors.first('password')"
            class="form-error"></li>
          <li
            v-if="errors.has('terms')"
            v-text="errors.first('terms')"
            class="form-error"></li>
        </ul>

        <div class="form-fieldset">
          <label
            for="email"
            v-text="$t('the_login.placeholder_email')"
            class="form-input-label"></label>
          <input
            type="text"
            id="email"
            v-model="email"
            name="email"
            v-validate="'required|email'"
            data-vv-validate-on="submit"
            autofocus
            class="form-input"
            :placeholder="$t('general.placeholder_required')"
          >
        </div>

        <div class="form-fieldset">
          <label
            for="password"
            v-text="$t('the_login.placeholder_password')"
            class="form-input-label"
          ></label>
          <input
            type="password"
            id="password"
            v-model="password"
            name="password"
            v-validate="'required'"
            data-vv-validate-on="submit"
            class="form-input"
            :placeholder="$t('general.placeholder_required')"
          >
        </div>
      </div>
      <div class="ml-4">
        <router-link
          :to="{name: 'password-reset'}"
          v-text="$t('the_login.button_forgot_password')"
          class="text-xs text-blue-500 hover:text-blue-600 focus:outline-none focus:text-blue-600"
        ></router-link>
      </div>
      <label class="flex mt-6 ml-4">
        <v-checkbox
          v-model="acceptTerms"
          name="terms"
          :validation="'required'"
        />
        <p
          v-html="$t('the_login.label_accept_terms')"
          class="w-3/4 ml-4 mt-1 text-sm leading-tight"></p>
      </label>
    </form>
  </div>
</template>

<script>
  import validator from '@/mixins/validator';
  import { mapActions } from 'vuex';

  import VCheckbox from '@/components/common/VCheckbox.vue';

  export default {
    name: 'TheLogin',
    components: {
      VCheckbox,
    },
    mixins: [validator],
    data() {
      return {
        email: null,
        password: null,
        acceptTerms: false,
        error: null,
        loading: false,
      };
    },
    computed: {
      loginDisabled() {
        return this.loading || !(this.email && this.password && this.acceptTerms);
      },
    },
    methods: {
      ...mapActions('auth', ['login']),
      onSubmit() {
        this.$validator.validate().then(() => {
          this.error = null;

          if (this.$validator.errors.items.length > 0) {
            return;
          }

          this.loading = true;
          this.login({
            username: this.email,
            password: this.password,
          })
            .then(() => {
              if (this.$route.query.redirect) {
                this.$router.push({ path: this.$route.query.redirect });
              } else {
                this.$router.push({ name: 'home' });
              }
            })
            .catch((error) => {
              this.password = null;

              if (error.response && error.response.data.message) {
                this.error = error.response.data.message;
              } else {
                this.error = this.$t('the_login.label_failed');
              }

              this.loading = false;
            });
        });
      },
    },
  };
</script>
